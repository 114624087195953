body {
  color: $font-color;

  #allbox {
    overflow: hidden;
  }

  h1, h2, h3, h4, h5, h6 {
    color: $font-color;
    font-weight: 500;
  }
  p, a, span, li, dt, dd, th, td, div {
    font-weight: 400;
    color: $font-color;
    letter-spacing: .12em;
  }
  h1, h2, h3, h4, h5, h6, p, a, span, li, dl, dt, dd, th, td, div, figure {
    margin: 0;
    line-break: strict;
  }
  a {
    transition: .5s;
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }
  ul, ol {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  img {
    max-width: 100%;
  }
}

.pc {
  @include sp {
    display: none !important;
  }
}
.sp {
  @include pc {
    display: none !important;
  }
}

.rwd002-container {
  margin: 0;

  @include pc {
    body.is--privacy &, body.is--profile &, body.is--sitemap & {
      margin-top: 50px;
    }
  }
}
.rwd002-block-freearea {
  margin: 0;
  padding: 0;
  &__inner {
    max-width: unset;
  }
}

.container {
  max-width: 1060px;
  margin-inline: auto;
  padding-inline: 50px;
  @include sp {
    padding-inline: 25px;
  }
}

@keyframes scroll_line {
  100% {
    transform: scaleX(0);
  }
}

.grd_btn {
  display: flex;
  align-items: center;
  width: 216px;
  height: 56px;
  padding-left: 30px;
  box-sizing: border-box;
  border-radius: 28px;
  position: relative;
  color: #fff;
  overflow: hidden;
  @include Mulish(700);
  font-size: 14px;
  letter-spacing: .14em;
  box-shadow: 5px 5px 12px rgba(13, 109, 182, 0.2);
  img {
    width: 12px;
    position: absolute;
    right: 20px;
    top: 0;
    bottom: 0;
    margin: auto;
    transition: $transition-base;
  }

  &::before, &::after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
  &::before {
    z-index: -2;
    background: linear-gradient(90deg, #007ED4, #32C8CE);
  }
  &::after {
    z-index: -1;
    transition: $transition-base;
    background: linear-gradient(90deg, #0F90BF, #8FE1E4);
  }
  &:hover {
    color: #fff;
    img {
      transform: translateX(5px);
    }
    &::after {
      opacity: 0;
    }
  }

  @include sp {
    margin-inline: auto;
  }
}

.bg_area {
  position: relative;
  &::before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    background: url(/assets/img/common/bg_grd@2x.jpg) no-repeat 0 / cover;
    left: 0;
    z-index: -1;
  }
  @keyframes wave_flow {
    100% {
      transform: translateX(-50%);
      @include sp {
        transform: translateX(-100%);
      }
    }
  }
  .wave {
    position: absolute;
    width: 200%;
    left: 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    animation: wave_flow 25s linear infinite;
    @include sp {
      width: 400%;
    }
  }
}
