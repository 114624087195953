footer {
  margin-top: 144px;
  position: relative;
  padding-block: 165px 25px;
  .top_wrap {
    padding-bottom: 86px;
    .info_wrap {
      .logo {
        width: 287px;
        a:hover {
          opacity: .7;
        }
      }

      .num_list {
        margin-top: 33px;
        li, a {
          @include Mulish(700);
          font-size: 15px;
          line-height: 1;
          letter-spacing: .1em;
          color: #fff;
        }
      }
      .address {
        margin-top: 10px;
        line-height: 1.2;
        font-size: 12px;
        font-weight: 700;
        letter-spacing: .1em;
        color: #d5d9e2;
        a{
          color: #d5d9e2;
          font-weight: 700;
          pointer-events: none;
        }
      }
      .btn_wrap {
        margin-top: 26px;
        a {
          display: flex;
          justify-content: center;
          align-items: center;
          column-gap: 7px;
          width: 152px;
          height: 35px;
          border-radius: 18px;
          border: 1px solid #D5D9E2;
          @include Mulish(700);
          padding-right: 3px;
          box-sizing: border-box;
          position: relative;
          .fa-location-dot {
            position: relative;
            bottom: 1px;
            font-size: 13px;
            color: #D5D9E2;
            transition: $transition-base;
          }
          span {
            font-size: 13px;
            letter-spacing: .14em;
            color: #D5D9E2;
            transition: $transition-base;
          }
          &:hover {
            background-color: #fff;
            i, span {
              color: $theme-blue;
            }
          }
        }
      }
    }

    .contact_list {
      a {
        font-size: 16px;
        letter-spacing: .16em;
        padding-left: 40px;
        box-shadow: unset;
        width: 268px;
        height: 67px;
        border-radius: 34px;
      }
    }
  }

  .bottom_wrap {
    padding-top: 26px;
    border-top: 1px solid #717E9D;
    ul {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      row-gap: 16px;
    }
    li, a {
      font-size: 12px;
      line-height: 1;
      letter-spacing: .2em;
    }
    li + li::before {
      content: '/';
      margin-inline: 10px;
    }
    nav a, .sub_nav a {
      border-bottom: 1px solid transparent;
      &:hover {
        color: $light_blue;
        border-bottom-color: $light_blue;
      }
    }

    nav {
      li, a {
        font-weight: 700;
        color: #D5D9E2;
      }
    }
    .sub_nav {
      margin-top: 14px;
      li, a {
        color: #BFC5D3;
        font-weight: 500;
      }
    }
    .copy {
      margin-top: 30px;
      line-height: 1;
      text-align: center;
      font-size: 10px;
      font-weight: 500;
      letter-spacing: .21em;
      line-height: 1;
      color: #BFC5D3;
    }
  }

  .pagetop {
    position: absolute;
    line-height: 1;
    a {
      color: $theme-blue;
      @include Roboto(400);
      font-size: 12px;
      letter-spacing: .26em;
      position: relative;
      &:hover {
        color: $light_blue;
      }
    }
  }

  .login {
    position: absolute;
    width: 13px;
    right: 23px;
    bottom: 24px;
    a:hover {
      opacity: .7;
    }
  }

  @include pc {
    position: relative;
    &::before, &::after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
    }
    &::before {
      width: 330px;
      height: calc(100% - 164px);
      background: linear-gradient(45deg, #00A8D4, #8FE1E4);
      right: 0;
      z-index: -2;
    }
    &::after {
      width: calc(100% - 60px);
      height: 100%;
      left: 0;
      background-color: #2B3F6D;
      border-top-right-radius: 7px;
      z-index: -1;
    }
    .container {
      max-width: 1130px;
      position: relative;
      z-index: 1;
    }
    .top_wrap {
      display: flex;
      justify-content: space-between;
      .info_wrap {
        .num_list {
          display: flex;
          li + li::before {
            content: '/';
            margin-inline: 12px;
          }
        }
      }
      .contact_list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 25px;
      }
    }
    @include max(1300px){
      .container {
        max-width: 1062px;
      }
    }
    @include max(1200px){
      padding-top: 100px;
      .container {
        max-width: calc(100% - 140px);
      }
      .top_wrap {
        flex-direction: column;
        align-items: center;
        row-gap: 30px;
        padding-bottom: 46px;
      }
      .info_wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
      }
    }

    .pagetop {
      right: 28px;
      top: 111px;
      transform: rotate(90deg);
      transform-origin: right center;
      a {
        padding-left: 80px;
        white-space: nowrap;
        &::before, &::after {
          content: '';
          display: block;
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          background-color: $theme-blue;
          transition: background-color $transition-base;
        }
        &::before {
          width: 6px;
          height: 6px;
          aspect-ratio: 1 / 1;
          border-radius: 50%;
        }
        &::after {
          width: 63px;
          height: 1px;
          transform-origin: left;
          animation: scroll_line 2s cubic-bezier(0, 0, 0.3, 0.96) infinite;
        }
        &:hover {
          &::before, &::after {
            background-color: $light_blue;
          }
        }
      }
    }
  }

  @include sp {
    margin-top: 150px;
    padding-block: 80px 43px;
    background-color: #2B3F6D;

    .top_wrap {
      padding-bottom: 50px;
      .info_wrap {
        .logo {
          margin-inline: auto;
        }
  
        .num_list {
          margin-top: 33px;
          text-align: center;
          li + li {
            margin-top: 10px;
          }
        }
        .address {
          margin-top: 13px;
          text-align: center;
          white-space: nowrap;
        }
        .btn_wrap {
          margin-top: 26px;
          a {
            padding-right: 3px;
            margin-inline: auto;
            .fa-location-dot {
              bottom: 1px;
            }
          }
        }
      }
  
      .contact_list {
        margin-top: 40px;
        position: relative;
        z-index: 1;
        li + li {
          margin-top: 13px;
        }
      }
    }
  
    .bottom_wrap {
      padding-top: 45px;
      ul {
        max-width: 280px;
        margin-inline: auto;
      }
      li:nth-of-type(4)::before {
        display: none;
      }
      .copy {
        margin: 56px auto 0;
        line-height: 2;
        max-width: 230px;
      }
    }
  
    .pagetop {
      left: 0;
      top: -42px;
      right: 0;
      margin: auto;
      max-inline-size: max-content;
      a {
        font-size: 12px;
        letter-spacing: .26em;
        position: relative;
        padding-top: 34px;
        &::before {
          content: '';
          display: block;
          position: absolute;
          width: 53px;
          height: 22px;
          background: url(/assets/img/common/arrow_pagetop.svg) no-repeat 0 / contain;
          left: 0;
          right: 0;
          top: 0;
          margin: auto;
        }
      }
    }
  
    .login {
      width: 13px;
      right: 37px;
      bottom: 51px;
    }
  }
}