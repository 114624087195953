body.is--about {
  #allbox {
    overflow: hidden;
  }
  .rwd002-detail {
    margin: 0;
    padding: 0;
    &__entry {
      max-width: unset;
      padding: 0;
      margin: 0;
    }
  }

  .link_list {
    display: grid;
    grid-template-columns: repeat(4, 155px);
    gap: 9px 13px;
    justify-content: center;
    a {
      display: grid;
      place-content: center;
      height: 40px;
      border-radius: 20px;
      border: 1px solid #dce0ee;
      font-size: 12px;
      font-weight: 700;
      letter-spacing: 0.08em;
      color: $theme-blue;
      position: relative;
      &::before {
        content: "";
        display: block;
        position: absolute;
        width: 6px;
        height: 6px;
        border-right: 1px solid $theme-blue;
        border-bottom: 1px solid $theme-blue;
        transform: rotate(45deg);
        right: 12px;
        top: 0;
        bottom: 0;
        margin: auto;
      }
      &:hover {
        opacity: 0.7;
      }
    }
  }

  .bg_area {
    padding-top: 286px;
    padding-bottom: 94px;
    margin-top: 16px;
    &::before {
      height: 100%;
      top: 0;
      background-image: url(/assets/img/about/bg_grd@2x.jpg);
    }
    .wave {
      top: -2px;
    }
  }

  .block-lead {
    h3 {
      font-size: 20px;
      font-weight: 700;
      line-height: 2;
      letter-spacing: 0.16em;
      color: $theme-blue;
      text-align: center;
    }
    p {
      font-size: 15px;
      font-weight: 700;
      line-height: 2.1;
      letter-spacing: 0.08em;
      color: $theme-blue;
      margin-top: 37px;
      @include pc {
        text-align: center;
      }
    }
    @include pc {
      position: relative;
      picture {
        position: absolute;
        width: 280px;
      }
      .img01 {
        right: calc(50% + 320px);
        bottom: -83px;
        img {
          width: 100%;
        }
      }
      .img02 {
        left: calc(50% + 320px);
        top: -54px;
      }
    }
  }

  .sec_ttl {
    display: grid;
    place-items: center;
    img {
      margin-bottom: 26px;
    }
    strong,
    small {
      line-height: 1;
      color: $theme-blue;
      letter-spacing: 0.1em;
    }
    strong {
      font-size: 28px;
      font-weight: 700;
    }
    small {
      @include Mulish(700);
      font-size: 12px;
      letter-spacing: 0.24;
      margin-top: 15px;
    }
  }

  .desc {
    font-size: 15px;
    font-weight: 500;
    line-height: 2;
    letter-spacing: 0.08em;
    color: $theme-blue;
    margin-top: 38px;
    @include pc {
      text-align: center;
    }
  }

  .block-service {
    margin-top: 154px;
    padding-block: 130px 90px;
    position: relative;
    &::before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      margin: auto;
      width: calc(100% - 90px);
      height: 100%;
      background-color: #fff;
      border-radius: 120px;
      z-index: -1;
      @include max(1100px) {
        width: calc(100% - 60px);
      }
    }
    .sec_ttl img {
      width: 95px;
      aspect-ratio: 73 / 75;
    }

    .service_list {
      margin: 84px auto 0;
      li {
        &:nth-of-type(1) h4 {
          color: #1b865e;
        }
        &:nth-of-type(2) h4 {
          color: #1972b7;
        }
        &:nth-of-type(3) h4 {
          color: #0084ab;
        }
        &:nth-of-type(4) h4 {
          color: #53b7e9;
        }
        &:nth-of-type(5) h4 {
          color: #87b946;
        }
      }
      picture {
        display: block;
        aspect-ratio: 293 / 263;
      }
      h4 {
        text-align: center;
        margin-top: 20px;
        font-size: 22px;
        font-weight: 700;
        line-height: 1.2;
        letter-spacing: 0.1em;
      }
      p {
        margin-top: 23px;
        font-size: 14px;
        font-weight: 500;
        line-height: 2;
        letter-spacing: 0.04em;
        color: #3a4871;
      }

      @include pc {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        max-width: 1000px;
        gap: 70px 5%;
        li {
          width: 29%;
        }
      }
    }
  }

  .column_area {
    section {
      + section {
        border-top: 1px solid #d5d5d5;
      }
      &:nth-of-type(2n + 1) {
        background-color: #fff;
      }
      &:nth-of-type(2n) {
        background-color: #f8f8f8;
      }
    }
  }

  .block-strength {
    padding-block: 135px 115px;
    .sec_ttl img {
      width: 123px;
      aspect-ratio: 112 / 75;
    }
  }

  .block-mission {
    padding-block: 100px 127px;
    .sec_ttl img {
      width: 112px;
      aspect-ratio: 49 / 50;
    }
  }

  .note {
    text-align: right;
    font-size: 11px;
    font-weight: 500;
    line-height: 1;
    letter-spacing: 0.05em;
  }

  .block-works {
    padding-block: 126px 106px;
    .works_list {
      margin-top: 56px;
      li {
        padding: 14px 3px;
        border-top: 1px solid #d5d5d5;
        box-sizing: border-box;
      }
      p {
        flex: 1;
        font-size: 15px;
        font-weight: 500;
        line-height: 1.4;
        letter-spacing: 0.08em;
      }
      .icon_wrap {
        display: flex;
        flex-wrap: wrap;
        gap: 6px 4px;
        img {
          width: 26px;
        }
      }

      @include pc {
        column-count: 2;
        column-gap: 54px;
        li {
          display: flex;
          align-items: center;
          column-gap: 15px;
        }
        .icon_wrap {
          justify-content: flex-end;
          width: 86px;
        }
      }
    }

    .note {
      margin-top: 23px;
    }
  }

  .block-qualifi {
    padding-block: 128px 126px;
    .qualifi_list {
      margin-top: 60px;
      li {
        display: flex;
        justify-content: space-between;
        column-gap: 15px;
        padding: 13px 4px;
        box-sizing: border-box;
        border-bottom: 1px solid #d5d5d5;
      }
      span {
        font-size: 14px;
        font-weight: 500;
        line-height: 1.3;
        letter-spacing: 0.06em;
        &:first-of-type {
          flex: 1;
        }
      }
    }
    .note {
      margin-top: 11px;
    }

    @include pc {
      position: relative;
      &::before {
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 200px;
        background-color: #f8f8f8;
        right: 0;
        bottom: -200px;
        z-index: -1;
      }
      .qualifi_list {
        column-gap: 107px;
        column-count: 2;
      }
    }
  }

  footer {
    margin-top: 0;
  }

  @include sp {
    .link_list {
      grid-template-columns: repeat(2, 1fr);
      max-width: 800px;
      margin-inline: auto;
      padding-inline: 25px;
    }

    .bg_area {
      padding-block: 110px 130px;
      margin-top: 44px;
    }

    .block-lead {
      .container {
        padding-inline: 21px;
      }
      h3 {
        line-height: 1.8;
        letter-spacing: 0.1em;
        @include max(380px) {
          font-size: 19px;
        }
      }
      p {
        line-height: 1.8;
        margin-top: 22px;
      }
      picture {
        display: block;
        margin: 57px -25px 0;
        aspect-ratio: 374 / 171;
      }
      .img01 {
        img {
          width: 100%;
        }
      }
    }

    .sec_ttl small {
      margin-top: 13px;
    }

    .desc {
      margin-top: 26px;
    }

    .block-service {
      margin-top: 80px;
      padding-block: 70px 65px;
      &::before {
        left: 50%;
        transform: translateX(-50%);
        width: 128%;
        border-radius: 134px;
      }
      .sec_ttl img {
        width: 103px;
      }

      .service_list {
        margin-top: 34px;
        li + li {
          margin-top: 40px;
        }
        p {
          margin-top: 20px;
          letter-spacing: 0.06em;
        }
        picture {
          img {
            width: 100%;
          }
        }
      }
      @media (min-width: 600px) {
        padding-block: 70px 85px;
        &::before {
          border-radius: 255px;
        }
      }
    }

    .block-strength {
      padding-block: 60px 66px;
    }

    .block-mission {
      padding-block: 63px 72px;
      .sec_ttl img {
        width: 119px;
      }
    }

    .block-works {
      padding-block: 64px 46px;
      .works_list {
        margin-top: 38px;
        li {
          padding-block: 15px 11px;
        }
        p {
          line-height: 1.3;
        }
        .icon_wrap {
          margin-top: 9px;
        }
      }
      .note {
        margin-top: 10px;
      }
    }

    .block-qualifi {
      padding-block: 67px 114px;
      .qualifi_list {
        margin-top: 30px;
        li {
          padding: 11px 4px;
        }
      }
      .note {
        margin-top: 15px;
      }
    }
  }
}
