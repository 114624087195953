.rwd002-gnav {
  @include media-up(lg) {
    margin-left: map-get($spacers, 3);
  }

  @include media-down(lg) {
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    width: 100%;
    z-index: 100;
    background-color: transparent;
    transform: translateX(100%);
    transition: background-color 0.2s ease-in-out, transform 0s 0.5s;
  }

  &.is-active {
    // メニューが開いたときのオーバーレイ
    @include media-down(lg) {
      background-color: fade-out($black, 0.2);
      transform: translateX(0%);
      transition: background-color 0.2s ease-in-out, transform 0s 0s;
    }
  }

  &__inner {
    @include pc {
      display: flex;
      column-gap: 25px;
      align-items: center;
    }

    // 格納メニュー
    @include media-down(lg) {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      padding: 76px map-get($spacers, 2) map-get($spacers, 2);
      width: calc(100vw - map-get($spacers, 5));
      transform: translateX(100%);
      transition: $transition-base;
      background-color: $white;
      overflow: auto;
      -webkit-overflow-scrolling: touch;
    }
  }

  .is-active &__inner {
    @include media-down(lg) {
      transform: translateX(0%);
    }
  }

  &__list {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    align-items: stretch;
    flex-direction: column;

    @include media-up(lg) {
      margin-right: -0.75em;
      align-items: stretch;
      flex-direction: row;
    }
  }

  // グローバルナビ　Item(親)
  &-item {
    display: flex;
    flex-wrap: wrap;
    font-size: 0.9375rem;
    letter-spacing: 0.14em;
    text-indent: 0.14em;
    font-feature-settings: "palt";

    @include media-down(lg) {
      border: solid $gray-300;
      border-width: 1px 0;
      & + & {
        border-top-width: 0;
      }
    }

    &__link {
      position: relative;
      display: flex;
      align-items: center;
      font-weight: $font-weight-bold;
      transition: $transition-base;
      padding: 0.25em 0 0.25em 1.5em;
      min-height: 3.3em;

      @include media-up(lg) {
        justify-content: center;
        height: 100%;
        padding: 0;
        min-height: 75px;
      }
      @include media-down(lg) {
        flex-grow: 1;
        flex-basis: calc(100% - 50px);
      }

      &:hover,
      &:focus,
      &.current {
        color: $primary;
        text-decoration: none;
      }

      // PC表示のメニュー装飾
      @include media-up(lg) {
        span {
          position: relative;
          padding: .5em .75em;
          font-size: 15px;
          font-weight: 700;
          color: $theme-blue;
          transition: $transition-base;
          &:after {
            content: "";
            position: absolute;
            top: 100%;
            left: 0;
            right: 0;
            margin: auto;
            height: 2px;
            width: 45px;
            max-width: 100%;
            background: linear-gradient(90deg, #00A8D4, #8FE1E4);
            transition: $transition-base;
            opacity: 0;
            transform-origin: left;
            transform: scaleX(0);
          }
        }
        &.current span, &:hover span {
          color: $light-blue;
          &:after {
            transform: scaleX(1);
            opacity: 1;
          }
        }

        @include max(1000px){
          span {
            padding-inline: 5px;
            font-size: 14px;
          }
        }
      }

      // SP表示のメニュー装飾
      @include media-down(lg) {
        &:after {
          position: absolute;
          content: "";
          top: calc(50% - 0.3em);
          left: 0;
          width: 1.5em;
          height: 0.6em;
          background: center / contain no-repeat;
          background-image: svgUrlFunc(
            '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path fill="#{$body-color}" d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"/></svg>'
          );
        }
      }
    }
  }

  &__contact {
    a {
      display: grid;
      place-content: center;
      position: relative;
      color: #fff;
      width: 133px;
      height: 36px;
      border-radius: 18px;
      @include Mulish(700);
      font-size: 14px;
      letter-spacing: .18em;
      overflow: hidden;
      z-index: 1;
      &::before, &::after {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
      }
      &::before {
        background: linear-gradient(90deg, #00A8D4, #8FE1E4);
        z-index: -2;
      }
      &::after {
        background-color: $theme-blue;
        z-index: -1;
        transition: $transition-base;
      }
      &:hover::after {
        opacity: 0;
      }
    }
    @include sp {
      margin-block: 20px 17px;
      a {
        margin-inline: auto;
      }
    }
  }

  &__copyright {
    @include media-up(lg) {
      display: none;
    }
    @include media-down(lg) {
      font-size: $font-size-xs;
      color: tint($body-color, 50%);
    }
  }
}

.rwd002-gnav-sublist {
  // sitemap.phpなどのシステムメニュー
  list-style: none;
  padding: 0;
  @include media-up(lg) {
    display: none;
  }
  @include media-down(lg) {
    display: flex;
    flex-wrap: wrap;
    margin: map-get($spacers, 2) 0;
    color: tint($body-color, 50%);
    font-size: $font-size-xs;
    margin-left: -0.5em;
    margin-right: -0.5em;
    &__item{
      position: relative;
      padding: 0.2em 0.6em;
    }
    &__item + &__item:before{
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(-50%,-50%);
      content: "";
      height: 1em;
      width: 1px;
      background-color: tint($body-color, 50%);
    }
  }
}

