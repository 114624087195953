.rwd002-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-inline: 70px 30px;
  height: 130px;
  z-index: 100;

  
  @include sp {
    height: 76px;
    padding-inline: 28px 20px;
    overflow: hidden;
  }

  &__logo {
    width: 265px;
    line-height: 0;
    @include sp {
      width: 240px;
    }
    a:hover {
      opacity: .7;
    }
  }

  @include max(1000px){
    padding-left: 30px;
    &__logo {
      width: 230px;
    }
  }
}

.rwd002-header__drawerBtn {
  @extend .btn;
  padding: 0;
  border-radius: 0;
  display: grid;
  place-content: center;
  position: relative;
  width: 38px;
  aspect-ratio: 1 / 1;
  background-color: $theme-blue !important;
  transition: $transition-base;
  z-index: 101;
  svg {
    width: 19px;
  }

  &:focus {
    box-shadow: none;
  }

  @include media-up(lg) {
    display: none;
  }

  &__bars {
    stroke: #fff;
  }

  &__bar01,
  &__bar02,
  &__bar03 {
    transform-origin: center;
    transition: $transition-base;
  }

  .is-active &__bar01 {
    transform: rotate(135deg) translateY(50%);
  }

  .is-active &__bar02 {
    transform: rotate(45deg);
    opacity: 0;
  }

  .is-active &__bar03 {
    transform: rotate(225deg) translateY(-50%);
  }
  &.is-active {
    background-color: #fff !important;
    svg path {
      stroke: $theme-blue;
    }
  }
}

body.menu_open {
  overflow: hidden;
}
