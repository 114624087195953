.rwd002-moduletitle {
  margin-top: map-get($spacers, 6);
  margin-bottom: map-get($spacers, 4);
  text-align: center;
  @include media-down(md) {
    margin-top: map-get($spacers, 4);
    margin-bottom: map-get($spacers, 4);
  }
  &__inner {
    @include c-module-title;
    padding-left: 1.6875rem;
    padding-right: 1.6875rem;
  }

  body.is--privacy &, body.is--profile &, body.is--sitemap & {
    display: none;
  }
}