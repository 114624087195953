.content-head-banner {
  display: grid;
  place-content: center;
  height: 175px;
  &__title {
    position: relative;
    strong {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      white-space: nowrap;
      @include Mulish(800);
      font-size: 90px;
      letter-spacing: .06em;
      color: #E8E8E8;
      z-index: -1;
    }
    span {
      font-size: 24px;
      font-weight: 700;
      letter-spacing: .12em;
      color: $theme-blue;
    }
  }

  @include sp {
    height: 126px;
    &__title {
      strong {
        top: -37px;
        transform: translateX(-50%);
        font-size: 53px;
        letter-spacing: .1em;
      }
      span {
        font-size: 20px;
        letter-spacing: .22em;
      }
    }  
  }
}