body.is--home {
  #allbox {
    overflow: hidden;
  }
  header {
    position: absolute;
    left: 0;
    top: 0;
    .rwd002-gnav-item__link {
      &.current span,
      &:hover span {
        color: $theme-blue;
      }
    }
  }

  svg.arrow {
    width: 12px;
    position: absolute;
    aspect-ratio: 12 / 11;
    transition: $transition-base;
    fill: none;
    stroke-miterlimit: 10;
    stroke-width: 2px;
    transition: $transition-base;
    stroke: $theme-blue;
  }

  .mainvisual {
    height: 100vh;
    height: 100svh;
    position: relative;
    z-index: 1;

    .main_img {
      position: absolute;
      left: 0;
      top: 0;
      width: 60%;
      opacity: 0;
    }
    .catch {
      position: absolute;
      top: 24vw;
      left: 44%;
      width: 42%;
      opacity: 0;
    }
    .recruit_wrap {
      position: absolute;
      right: 0;
      bottom: 20px;
      width: 450px;
      height: 130px;
      z-index: 1;
      a {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        padding-left: 40px;
        position: relative;
        dl {
          dt,
          dd {
            color: #fff;
            line-height: 1;
          }
          dt {
            @include Mulish(400);
            font-size: 11px;
            letter-spacing: 0.36em;
          }
          dd {
            margin-top: 16px;
            font-size: 18px;
            font-weight: 700;
            letter-spacing: 0.26em;
          }
        }
        .icon {
          width: 123px;
          position: absolute;
          right: 79px;
          bottom: 17px;
          transition: $transition-base;
        }
        .arrow {
          display: block;
          position: absolute;
          width: 12px;
          right: 18px;
          bottom: 17px;
          transition: $transition-base;
        }
        &::before,
        &::after {
          content: "";
          display: block;
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          border-radius: 5px 0 0 5px;
          @media(max-width:740px){
            border-radius: 5px;
          }
        }
        &::before {
          z-index: -2;
          background: linear-gradient(90deg, #0f90bf, #8fe1e4);
        }
        &::after {
          z-index: -1;
          transition: $transition-base;
          background: linear-gradient(90deg, #007ed4, #18c1c8);
        }
        &:hover {
          color: #fff;
          .icon {
            transform: scale(1.06);
          }
          .arrow {
            transform: translateX(5px);
          }
          &::after {
            opacity: 0;
          }
        }
      }
    }
    @include pc {
      .scroll {
        position: absolute;
        left: 28px;
        bottom: 140px;
        transform: rotate(90deg);
        transform-origin: left center;
        padding-right: 80px;
        color: #2b3f6d;
        font-family: "Roboto Condensed";
        &::before,
        &::after {
          content: "";
          display: block;
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          background-color: #2b3f6d;
        }
        &::before {
          width: 6px;
          height: 6px;
          aspect-ratio: 1 / 1;
          border-radius: 50%;
        }
        &::after {
          width: 63px;
          height: 1px;
          transform-origin: right;
          animation: scroll_line 2s cubic-bezier(0, 0, 0.3, 0.96) infinite;
        }
      }

      @include max(1400px) {
        .main_img {
          left: calc(50% - 700px);
          width: 836px;
        }
        .catch {
          top: 34%;
          left: auto;
          right: 100px;
          width: 583px;
        }
      }
    }

    &.loaded {
      .main_img,
      .catch {
        opacity: 1;
        transition: opacity 2s;
      }
      .main_img {
        transition-delay: 0.3s;
      }
      .catch {
        transition-delay: 0.6s;
      }
    }
  }

  .bg_area {
    padding-top: 150px;
    &::before {
      height: calc(100% + 489px);
      background-position: center bottom;
      top: -214px;
    }
    .wave {
      top: -216px;
    }
  }

  .unit_text {
    h2 {
      line-height: 1;
      position: relative;
      strong {
        color: #fff;
        @include Mulish(800);
        font-size: 90px;
        letter-spacing: 0.06em;
        opacity: 0.6;
      }
      small {
        color: #1f89d1;
        font-size: 15px;
        font-weight: 700;
        letter-spacing: 0.22em;
        position: relative;
      }
      @include pc {
        strong {
          position: absolute;
          top: -39px;
          left: -120px;
        }
        small {
          padding-right: 53px;
          &::before {
            content: "";
            display: block;
            position: absolute;
            width: 50px;
            height: 1px;
            background-color: #1f89d1;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
          }
        }
      }
    }
    h3,
    p {
      color: $theme-blue;
      font-weight: 700;
    }
    h3 {
      margin-top: 53px;
      font-size: 30px;
      line-height: 1.5;
      letter-spacing: 0.1em;
    }
    p {
      margin-top: 30px;
      font-size: 15px;
      line-height: 2.1;
      letter-spacing: 0.06em;
    }
    .btn_wrap {
      margin-top: 36px;
    }
  }

  .block-about {
    position: relative;
    z-index: 1;

    @include pc {
      .unit {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        align-items: flex-end;
        column-gap: 60px;
        &_text h2 strong {
          left: -149px;
        }
      }
    }

    .link_list {
      margin-top: 110px;
      @include pc {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 44px;
      }

      a {
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-radius: 5px;
        box-sizing: border-box;
        width: 100%;
        height: 130px;
        padding-left: 50px;
        position: relative;
        overflow: hidden;
        box-shadow: 5px 5px 12px rgba(196, 214, 230, 0.3);

        small,
        span {
          letter-spacing: 0.2em;
          line-height: 1;
          color: $theme-blue;
        }
        small {
          @include Mulish(400);
          font-size: 11px;
        }
        span {
          margin-top: 15px;
          font-size: 18px;
          font-weight: 700;
        }
        svg {
          right: 16px;
          bottom: 14px;
        }

        &::before,
        &::after {
          content: "";
          display: block;
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          border-radius: 5px 0 0 5px;
        }
        &::before {
          z-index: -2;
          background: linear-gradient(90deg, #0f90bf, #8fe1e4);
        }
        &::after {
          z-index: -1;
          transition: $transition-base;
          background-color: #e0edf0;
        }
        &:hover {
          small,
          span {
            color: #fff;
          }
          svg {
            transform: translateX(5px);
            stroke: #fff;
          }
          &::after {
            opacity: 0;
          }
        }
      }
    }
  }

  .block-company {
    margin-top: 140px;
    z-index: 1;

    @include pc {
      position: relative;
      .first_img {
        position: absolute;
        left: 0;
        top: -60px;
        width: 140px;
        @include max(1300px) {
          left: calc(50% - 650px);
        }
      }
      .container {
        max-width: 1300px;
      }
      .unit {
        display: grid;
        grid-template-columns: 1fr 45.4%;
        column-gap: 50px;
        align-items: center;
      }
      .unit_text {
        max-width: 445px;
        margin-inline: auto 0;
        @include max(1150px) {
          h2 strong {
            left: -25px;
          }
        }
        h3 {
          margin-top: 53px;
        }
        p {
          margin-top: 30px;
        }
        .btn_wrap {
          margin-top: 36px;
        }
      }
      picture {
        position: relative;
        bottom: 16px;
      }
    }
  }

  .block-recruit {
    margin-top: 170px;
    .inner {
      position: relative;
      .recruit_bg {
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 237px;
        background: linear-gradient(160deg, #0097d4, #57d2d6);
        inset: 0;
        margin: auto;
        z-index: -1;
      }
      .recruit_contents {
        opacity: 0;
        transition: 0.3s;
        &.visible {
          opacity: 1;
        }
        .img{
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    small,
    h3,
    p {
      color: #fff;
    }
    h2 {
      line-height: 1;
      small {
        font-size: 15px;
        font-weight: 700;
        letter-spacing: 0.22em;
        color: #fff;
      }
    }
    h3 {
      font-size: 30px;
      font-weight: 700;
      line-height: 1.5;
      letter-spacing: 0.12em;
    }
    p {
      font-size: 15px;
      font-weight: 700;
      line-height: 2.1;
      letter-spacing: 0.08em;
    }
    .btn_wrap {
      .grd_btn {
        z-index: 1;
        &::before {
          background: linear-gradient(90deg, #007ed4, #32c8ce);
        }
        &::after {
          background: linear-gradient(90deg, #38bbdd, #8fe1e4);
        }
      }
    }
    @include pc {
      .container {
        max-width: 1373px;
      }
      .inner {
        padding-left: 55%;
        padding-right: 30px;
        position: relative;
        min-height: 650px;
        display: grid;
        align-items: center;
        place-content: center;
        justify-content: flex-start;
      }
      .img {
        position: absolute;
        display: block;
        left: 7%;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 42%;
        aspect-ratio: 276 / 211;
      }
      h2 {
        position: relative;
        picture {
          display: block;
          position: absolute;
          width: 355px;
          left: 128px;
          top: -137px;
        }
        small {
          position: relative;
          &::before {
            content: "";
            display: block;
            position: absolute;
            width: 97px;
            height: 1px;
            background-color: #fff;
            left: 84px;
            top: 0;
            bottom: 0;
            margin: auto;
          }
        }
      }
      h3 {
        margin-top: 30px;
      }
      p {
        margin-top: 35px;
      }
      .btn_wrap {
        margin-top: 35px;
        z-index: 1;
      }
      @include max(1100px) {
        .inner .recruit_bg {
          border-radius: 80px;
        }
        h2 picture {
          left: 0;
        }
      }
    }
  }

  .block-news {
    margin-top: 150px;
    h2 {
      strong,
      small {
        display: block;
        line-height: 1;
        color: $light_blue;
      }
      strong {
        @include Mulish(800);
        font-size: 63px;
        letter-spacing: 0.12em;
      }
      small {
        font-size: 15px;
        font-weight: 700;
        letter-spacing: 0.22em;
        margin-top: 15px;
      }
    }
    .news_list {
      margin-top: 50px;
      box-sizing: border-box;

      a {
        padding: 5px 40px 40px;
        position: relative;
        box-sizing: border-box;
        display: block;

        p,
        span,
        svg {
          transition: $transition-base;
        }

        .info {
          display: flex;
          align-items: center;
          column-gap: 12px;
          .date {
            @include Mulish(700);
            font-size: 13px;
            letter-spacing: 0.1em;
            color: #627597;
          }
          .cate {
            color: #fff;
            display: grid;
            place-content: center;
            min-width: 92px;
            height: 20px;
            border-radius: 10px;
            padding-inline: 15px;
            box-sizing: border-box;
            background-color: #627597;
            font-size: 11px;
            font-weight: 700;
            letter-spacing: 0.18em;
          }
        }
        .ttl {
          margin-top: 18px;
          font-weight: 700;
          line-height: 1.9;
          letter-spacing: 0.12em;
          color: $theme-blue;
        }
        svg {
          position: absolute;
          right: 24px;
          bottom: 5px;
          width: 27px;
          aspect-ratio: 1 / 1;
          fill: none;
          stroke: #627597;
          .circle {
            stroke-miterlimit: 10;
          }
          .arrow {
            stroke-linecap: round;
            stroke-linejoin: round;
          }
        }
        &:hover {
          .info .date,
          .ttl {
            color: $light_blue;
          }
          .cate {
            background-color: $light_blue;
          }
          svg {
            transform: translateX(5px);
            stroke: $light_blue;
          }
        }
      }

      @include pc {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        li {
          border-right: 1px solid #d5d5d5;
          &:first-of-type {
            border-left: 1px solid #d5d5d5;
          }
        }
      }
    }

    .btn_wrap {
      margin-top: 56px;
      a {
        border-bottom: 2px solid $theme-blue;
        line-height: 1;
        padding-bottom: 10px;
        padding-right: 26px;
        position: relative;
        display: block;
        max-inline-size: max-content;
        margin-inline: auto 0;

        span {
          font-size: 14px;
          font-weight: 700;
          letter-spacing: 0.22em;
          color: $theme-blue;
          transition: $transition-base;
        }
        svg {
          right: 0;
          top: 2px;
        }
        &:hover {
          border-bottom-color: $light_blue;
          span {
            color: $light_blue;
          }
          svg {
            transform: translateX(3px);
            stroke: $light_blue;
          }
        }
      }
    }
  }

  @include sp {
    .mainvisual {
      .main_img {
        width: 100%;
        img {
          width: 100%;
        }
      }
      .catch {
        top: 27%;
        left: 20%;
        width: 70%;
      }
      .recruit_wrap {
        left: 0;
        margin: auto;
        bottom: 20px;
        width: calc(100% - 50px);
        height: 125px;
        a {
          padding-left: 40px;
          &::before {
            border-radius: 5px;
          }
          dl {
            dt {
              font-size: 11px;
            }
            dd {
              margin-top: 8px;
              font-size: 18px;
              line-height: 1.3;
            }
          }
          .icon {
            width: 119px;
            right: 45px;
            bottom: 20px;
          }
          .arrow {
            right: 18px;
            bottom: 17px;
          }
        }
        @media (min-width: 740px) {
          left: auto;
          right: 0;
          width: 450px;
          height: 130px;
          a {
            dl {
              dd {
                margin-top: 16px;
                line-height: 1;
                .sp{
                  display: none;
                }
              }
            }
            .icon {
              width: 123px;
              right: 79px;
              bottom: 17px;
            }
          }
        }
      }
    }

    .bg_area {
      padding-top: 70px;
      &::before {
        height: calc(100% + 476px);
        top: -236px;
      }
      .wave {
        top: -236px;
      }
    }

    .unit_text {
      h2 {
        text-align: center;
        strong,
        small {
          display: block;
          line-height: 1;
        }
        strong {
          font-size: 53px;
          letter-spacing: 0.1em;
        }
        small {
          margin-top: -15px;
        }
      }
      h3 {
        margin-top: 40px;
        font-size: 23px;
        line-height: 1.6;
      }
      p {
        margin-top: 22px;
        line-height: 1.9;
        letter-spacing: 0.08em;
      }
    }

    .block-about {
      .unit {
        padding-top: 100px;
        h2 {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
        }
        @media(min-width:740px){
          padding-top: 170px;
          h2{
            top: 70px;
          }
        }
      }

      .link_list {
        margin-top: 53px;
        li + li {
          margin-top: 12px;
        }
        a {
          height: 114px;
          padding-left: 38px;
        }
      }
    }

    .block-company {
      margin-top: 77px;

      .first_img {
        margin-inline: -25px;
        display: block;
        img{
          width: 100%;
        }
      }

      .unit {
        margin-top: 28px;
      }
    }

    .block-recruit {
      margin-top: 90px;
      .inner {
        padding-block: 90px 87px;
        .recruit_bg {
          border-radius: 80px;
          width: 130%;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          @media(min-width:600px){
            border-radius: 225px;
          }
        }
      }
      h2 {
        display: grid;
        place-items: center;
        img {
          width: 231px;
        }
        small {
          margin-top: 12px;
        }
      }
      .img {
        margin-top: 30px;
        display: block;
      }
      h3 {
        font-size: 23px;
        line-height: 1.6;
        letter-spacing: 0.1em;
        text-align: center;
        margin-top: 17px;
      }
      p {
        line-height: 1.8;
        letter-spacing: 0.09em;
        margin-top: 26px;
      }
      .btn_wrap {
        margin-top: 27px;
      }
    }

    .block-news {
      margin-top: 87px;
      h2 {
        text-align: center;
        strong {
          font-size: 63px;
          letter-spacing: 0.12em;
        }
        small {
          font-size: 15px;
          letter-spacing: 0.22em;
          margin-top: 15px;
        }
      }
      .news_list {
        margin-top: 33px;
        li + li {
          border-top: 1px solid #d5d5d5;
        }
        a {
          padding: 23px 60px 30px 8px;

          .info {
            column-gap: 17px;
          }
          .ttl {
            margin-top: 20px;
            line-height: 1.8;
            letter-spacing: 0.14em;
          }
          svg {
            right: 8px;
            bottom: 32px;
          }
        }
      }

      .btn_wrap {
        margin-top: 20px;
      }
    }
  }
}
